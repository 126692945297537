import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Physical vapor deposition tools`}</h4>
    <p>{`Physical vapor deposition (PVD) tools vaporize a solid or liquid material, which then condenses onto a substrate. The primary PVD method is called "sputtering." The United States controls most of the PVD equipment market, with Japan capturing most of the rest.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      